import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlogPost");
    const siteTitle = get(this.props, "data.site.siteMetadata.title");

    return (
      <>
        {/* <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet> */}
        <Layout>
          <div className="container">
            <Helmet title={`${post.title} | ${siteTitle}`} />
            <article className="content">
              {post.heroImage.fluid ? (
                <figure className="post-feature-image">
                  <Img alt={post.title} fluid={post.heroImage.fluid} />
                </figure>
              ) : null}
              <section className="post-full-content">
                <h1 className="content-title">{post.title}</h1>
                <p
                  style={{
                    display: "block",
                  }}
                >
                  {post.publishDate}
                </p>
                {/* The main post content */}
                <section
                  className="content-body load-external-scripts"
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
              </section>
            </article>
          </div>
        </Layout>
      </>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
